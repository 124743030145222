/* HalloweenButton.css */
.halloween-button {
  position: relative;
  display: block;
  background-color: #424242;
  color: #FF4500; /* Orange color for the text */
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 0.3s ease;
  margin: 20px 10px;
  margin-top: 30px;
  font-weight: bold;
}

.halloween-button:hover {
  background-color: #333;
}

.cobweb {
  position: absolute;
  width: 60px;
  height: 60px;
  background: url('../../assets/img/web.png') no-repeat center;
  background-size: cover;
  opacity: 1;
  padding: 50px;
  transition: opacity 0.3s ease;
}

.halloween-button:hover .cobweb {
  opacity: 1;
}

.top-left {
  top: 0;
  left: 0;
}

.top-right {
  top: -10px;
  right: -10px;
  /* flip image */
  transform: scaleX(-1);
}

.bottom-left {
  bottom: -10px;
  left: -10px;
}

.bottom-right {
  bottom: -10px;
  right: -10px;
}
