.mainNavBarColor {
    background: #171941 !important;
}

.slick-slide.slick-active.slick-center.slick-current {
    padding: 11px !important;
}

body {
    background-color: #171941 !important;
    background-image: url(../img/dots.png);
    background-size: contain;
}

.navbar {
    background: #171941 !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .2);
    /*background: #2a2a32 !important;*/
    height: 70px;
}

.description {
    white-space: break-spaces;
}

.pull-right {
    float: right;
}

.header-3 .slick-slide.slick-current.slick-active {
    opacity: 1;
    height: 50px !important;
}


@media screen and (max-width: 991px) {
    .gallery-logo {
       display: none;
    }
    /* .my-gallery {
        margin-top: 0px !important;
    } */
}


select {
    -webkit-appearance: menulist-button;
    color: black;
}

.qyt {
    width: 70px;
    position: absolute;
    top: auto;
    left: 100%;
    margin-top: 9px;
    margin-left: -86px !important;
    z-index: 100;
    color: black !important;
}

#mapid {
    height: 100%;
    width: 100%;
    left: 0;
    z-index: 0;
}

.map2 {
    position: relative;
    overflow: hidden;
    width: 100%;
    left: 0;
    height: 400px;
    background-color: rgb(229, 227, 223);
}

.contactus-4 .map:after {
    background: radial-gradient(400px circle at 60% 50%, transparent 0%, #171941 100%) !important;
}

.bg-info {
    background-color: #b9b9b98c !important;
}

.side-menu {
    background: #000128 !important;
}

.side-menu-toggle {
    display: block !important;
}

.navbar-brand {
    margin-top: 5px;
}




.glow-line {
    position: initial;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    width: 100%;
    height: 1px;
    border-radius: 50%;
    background-color: #b3b3b3;
    margin-top: 30px;
    margin-bottom: 30px !important;
}



ul.navbar-nav.ml-auto {
    padding: 10px;
    margin-top: 0px;
}

.nav-item .nav-link.active {
    font-weight: bold;
}

.page-header.page-header-small {
    min-height: 75vh;
}


@media screen and (min-width: 992px) {
    .bottom-of-page {
        display: none;
    }

    i.tim-icons.icon-align-left-2 {
        display: none;
    }


}

#myVideo {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    left: 0;
}

.myVideo {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: fill;
    z-index: -1;
    left: 0;
}

.VideoFlyer {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    left: 0;
}

.VideoFlyerHeader {
    width: 10px;
    height: 10px;
    z-index: -1;
    left: 0;
}


.video-logo {
    display: none;
}

@media screen and (max-width: 991px) {
    .video-logo {
        display: block;
        position: absolute;
        width: 200px;
        margin-left: 50%;
        left: 0px;
        margin-left: 100px;
        filter: brightness(0) invert(1);
        opacity: 0.5;

    }

    .navbar {
        box-shadow: none;
    }

    ul.navbar-nav.ml-auto {
        margin-top: 20px;
    }

    .navbar .navbar-brand {
        margin-top: -11px !important;
    }

    .navbar-brand {
        margin-top: 5px !important;
    }

    .side-menu .navbar-brand {
        margin-top: 13px !important;
        margin-left: 20px;
    }

    .bottom-of-page {
        display: block;
        position: fixed;
        background-image: linear-gradient(to bottom left, #e14eca, #ba54f5, #e14eca);
        z-index: 9000;
        color: white;
        top: 100% !important;
        left: 50%;
        margin-top: -80px !important;
        margin-left: -20px;
        border-radius: 30px;
        -webkit-box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        -moz-box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    }

    .navbar-toggler-bar {
        display: block !important;
        position: relative;
        width: 22px;
        height: 1px;
        border-radius: 1px;
        background: #ffffff;
    }

    /*#myVideo {
    position: absolute;
    z-index: -1;
    height: 75%;
  }*/

    .page-header.page-header-small {
        min-height: unset;
        max-height: unset;
    }

}



.side-menu-toggle span.toggle-bars {
    height: 3px;
    width: 57%;
    top: 23px;
    left: 9px;
    margin: 0px;
}

.event-date {
    color: white;
    margin-bottom: -30px;
    font-size: 14px;
}

address {
    color: white;
    font-size: 12px;
    display: block;
    font-style: italic;
}

.branding-ticket-company {
    margin-bottom: -26px;
}

.branding-ticket-company img {
    height: 18px;
    margin-left: 2px;
}

.card-img-top {
    overflow: hidden;
    object-fit: cover;
}

span.event-date {
    font-size: 12px;
}

a.btn.btn-primary {
    margin-top: 12px;
}

.card-media-object-container {
    text-align: center;
    vertical-align: middle;
    line-height: 130px;

    background: #e14eca !important;
    background-image: -webkit-linear-gradient(to bottom left, #e14eca, #ba54f5, #e14eca) !important;
    background-image: -o-linear-gradient(to bottom left, #e14eca, #ba54f5, #e14eca) !important;
    background-image: -moz-linear-gradient(to bottom left, #e14eca, #ba54f5, #e14eca) !important;
    background-image: linear-gradient(to bottom left, #e14eca, #ba54f5, #e14eca) !important;
    background-size: 210% 210% !important;
    background-position: top right !important;
    background-color: #e14eca !important;
    transition: all 0.15s ease !important;
    box-shadow: none !important;
    color: #ffffff !important;

}

.card-media-object-container span {
    font-size: 30px;
    color: white;
    font-weight: 700;
    text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.53);
}



.u-float-right {
    float: right;
}

.u-flex-center {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
}

.subtle {
    color: #888;
    font-size: 12px;
}

.card-media {
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .12);
    height: 125px;
    margin-bottom: 25px;
    transition: all 300ms ease-out;
    width: 100%;
}



.card-media-object-container {
    background: none;
    float: left;
    height: 100%;
    width: 35%;
    position: relative;
}

.card-media-object {
    background-position: center center;
    background-size: cover;
    height: 100%;
}

.card-media-object:after {
    content: " ";
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 300ms ease-out;
    z-index: 10;
}



.card-media-object-tag {
    background-color: #fff;
    border-radius: 2px;
    padding: 2px 7px;
    position: absolute;
    right: 10px;
    top: 10px;
}

.card-media-object-social-list {
    bottom: 4px;
    left: 10px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 20;
}

.card-media-object-social-list li {
    border-radius: 50%;
    display: inline-block;
    height: 30px;
    margin-right: 6px;
    opacity: 0;
    overflow: hidden;
    transform: translateY(5px);
    transition: all 300ms ease-out;
    width: 30px;
}



.card-media-object-social-list li:nth-child(1) {
    transition-delay: 0;
}

.card-media-object-social-list li:nth-child(2) {
    transition-delay: 75ms;
}

.card-media-object-social-list li:nth-child(3) {
    transition-delay: 150ms;
}

.card-media-object-social-list-item-additional {
    border: 1px solid #fff;
    color: #fff;
    font-size: 12px;
    padding-top: 7px;
    text-align: center;
}

.card-media-body {
    background-color: #fff;
    float: left;
    height: 100%;
    padding: 12px 15px;
    position: relative;
    width: 65%;
}

.card-media-body-top {
    display: block;
}

.card-media-body-top-icons {
    margin-top: -2px;
    opacity: 0;
    transition: all 300ms ease-out;
    transform: translateY(-5px);
}


.card-media-body-top-icons>svg {
    cursor: pointer;
    margin-left: 10px;
    transition: all 300ms ease-out;
}


.card-media-body-heading {
    display: block;
    margin-top: 10px;
    line-height: 20px;
}

.card-media-body-supporting-bottom {
    position: absolute;
    bottom: 10px;
    left: 0;
    opacity: 1;
    padding: 0 15px;
    transition: all 300ms ease-out;
    width: 100%;
}



.card-media-body-supporting-bottom-text {
    display: inline-block;
}

.card-media-body-supporting-bottom-reveal {
    opacity: 0;
    transform: translateY(8px);
    transition: all 300ms ease-out;
}

.card-media-link {
    color: #41C1F2;
    text-decoration: none;
}

.pin_dash {
    font-size: 30px;
    margin-right: 14px;
    margin-left: -14px;
}

.pin-code {
    padding: 0;
    display: flex;
    justify-content: center;

}

.pin-code input {
    text-align: center;
    width: 58px;
    height: 52px;
    font-size: 31px;
    margin-right: 30px;
}


.pin-code input:focus {
    border: 1px solid #573D8B;
    outline: none;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.navbar-nav .ember-view {
    display: contents;
}


.wrapMe {
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 334px;
}

.info ul li {
    margin-bottom: 57px;
}

span.entry.pull-right {
    text-align: right;
}

span.my-icon.pull-left {
    font-size: 38px;
    margin-top: -15px;
    margin-left: -34px;
}

.navbar .navbar-brand {
    margin-top: 47px;
}


.reset-this {
    animation: none !important;
    animation-delay: 0 !important;
    animation-direction: normal !important;
    animation-duration: 0 !important;
    animation-fill-mode: none !important;
    animation-iteration-count: 1 !important;
    animation-name: none !important;
    animation-play-state: running !important;
    animation-timing-function: ease !important;
    backface-visibility: visible !important;
    background: 0 !important;
    background-attachment: scroll !important;
    background-clip: border-box !important;
    background-color: transparent !important;
    background-image: none !important;
    background-origin: padding-box !important;
    background-position: 0 0 !important;
    background-position-x: 0 !important;
    background-position-y: 0 !important;
    background-repeat: repeat !important;
    background-size: auto auto !important;
    border: 0 !important;
    border-style: none !important;
    border-width: medium !important;
    border-color: inherit !important;
    border-bottom: 0 !important;
    border-bottom-color: inherit !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-style: none !important;
    border-bottom-width: medium !important;
    border-collapse: separate !important;
    border-image: none !important;
    border-left: 0 !important;
    border-left-color: inherit !important;
    border-left-style: none !important;
    border-left-width: medium !important;
    border-radius: 0 !important;
    border-right: 0 !important;
    border-right-color: inherit !important;
    border-right-style: none !important;
    border-right-width: medium !important;
    border-spacing: 0 !important;
    border-top: 0 !important;
    border-top-color: inherit !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-top-style: none !important;
    border-top-width: medium !important;
    bottom: auto !important;
    box-shadow: none !important;
    box-sizing: content-box !important;
    caption-side: top !important;
    clear: none !important;
    clip: auto !important;
    color: inherit !important;
    columns: auto !important;
    column-count: auto !important;
    column-fill: balance !important;
    column-gap: normal !important;
    column-rule: medium none currentColor !important;
    column-rule-color: currentColor !important;
    column-rule-style: none !important;
    column-rule-width: none !important;
    column-span: 1 !important;
    column-width: auto !important;
    content: normal !important;
    counter-increment: none !important;
    counter-reset: none !important;
    cursor: auto !important;
    direction: ltr !important;
    display: inline !important;
    empty-cells: show !important;
    float: none !important;
    font: normal !important;
    font-family: inherit !important;
    font-size: medium !important;
    font-style: normal !important;
    font-variant: normal !important;
    font-weight: normal !important;
    height: auto !important;
    hyphens: none !important;
    left: auto !important;
    letter-spacing: normal !important;
    line-height: normal !important;
    list-style: none !important;
    list-style-image: none !important;
    list-style-position: outside !important;
    list-style-type: disc !important;
    margin: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
    max-height: none !important;
    max-width: none !important;
    min-height: 0 !important;
    min-width: 0 !important;
    opacity: 1 !important;
    orphans: 0 !important;
    outline: 0 !important;
    outline-color: invert !important;
    outline-style: none !important;
    outline-width: medium !important;
    overflow: visible !important;
    overflow-x: visible !important;
    overflow-y: visible !important;
    padding: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    page-break-after: auto !important;
    page-break-before: auto !important;
    page-break-inside: auto !important;
    perspective: none !important;
    perspective-origin: 50% 50% !important;
    position: static !important;
    /* May need to alter quotes for different locales (e.g fr) */
    quotes: '\201C' '\201D' '\2018' '\2019' !important;
    right: auto !important;
    tab-size: 8 !important;
    table-layout: auto !important;
    text-align: inherit !important;
    text-align-last: auto !important;
    text-decoration: none !important;
    text-decoration-color: inherit !important;
    text-decoration-line: none !important;
    text-decoration-style: solid !important;
    text-indent: 0 !important;
    text-shadow: none !important;
    text-transform: none !important;
    top: auto !important;
    transform: none !important;
    transform-style: flat !important;
    transition: none !important;
    transition-delay: 0s !important;
    transition-duration: 0s !important;
    transition-property: none !important;
    transition-timing-function: ease !important;
    unicode-bidi: normal !important;
    vertical-align: baseline !important;
    visibility: visible !important;
    white-space: normal !important;
    widows: 0 !important;
    width: auto !important;
    word-spacing: normal !important;
    z-index: auto !important;
    /* basic modern patch */
    all: initial !important;
    all: unset !important;
}

.page-header .page-header-image {
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat !important;
}

.page-header .page-header-image-rentals {
    position: absolute;
    background-image: url('../img/rental/1.jpg');
    background-size: cover;
}

.slide-center img {
    margin-top: 100px;
    object-fit: contain !important;
}

.header-3 .slick-slide {
    opacity: -0.1;
}


.contactus-4 .info {
    min-height: 320px !important;
}

.form-control {
    background: transparent !important;
}


input:focus,
.form-control:active {
    background: transparent !important;
}

.headerLogo {
    height: 300px;
}

.slider-image {
    object-fit: contain;
}

.slider-image {
    position: absolute;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}

.slider-image {
    margin-top: 100px;
    object-fit: contain !important;
}

.slider-image {
    opacity: -0.1;
}

.Deck {
    transition: transform 0.3s ease;
}

.Deck-selected {
    transform: scale(1);
}

.Deck-unselected {
    transform: scale(0.3);
}

.swiper-fixed-width-300 {
    width: 300px;
}



.swiper-container {
    width: 100%;
    height: 200px;
}

.page-header.page-header-small {
    min-height: none !important;
    max-height: none !important;
    height: auto !important;
    width: 100% !important;
}

.header-filter:before {
    background-color: rgba(0, 0, 0, 0.2) !important;
}

.space-110 {
    height: 110px;
    display: block;
}

@media only screen and (max-width: 992px) {
    .navbar-nav {
        display: block !important;
    }
}

.main-logo {
    margin-bottom: 45px;
}

@media only screen and (max-width: 992px) {
    .main-logo {
        margin-bottom: 0;
    }
}

.errorMessage {
    font-size: 12px;
    padding: 3px;
    color: red;
    display: none;
}

input:invalid[focused='true'] {
    border: 1px solid red !important;
}

input:invalid[focused='true']~.errorMessage {
    display: block;
}

.three-logo {
    height: 220px;
    width: 100%;
    z-index: 50050;
}

.modal.show .modal-dialog {
    transform: translate(0, -2%);
}

.popup-modal-footer {
    text-align: center;
    margin-left: 25px;
    margin-top: -56px;
    margin-right: 9px;
}

.popup-modal-footer Button {
    margin-right: 10px;
}

.big-button {
    font-size: 30px;
}

.each-slide-effect>div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 350px;
}

.each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
}


/* .title.x2 {
    font-size: 30px;
}

.title.x4 {
    font-size: 80px;
}

.rental-facts li {
    font-size: 18px;
    margin-bottom: 10px;
} */

.player-wrapper {
    position: relative;
    padding-top: 56.25%
        /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.avatar {
    background-color: #000000 !important;
}

.card-blog .card-image img {
    object-fit: cover !important;
}

.lg-react-element {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px;
}

.lg-react-element img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.image-container {
    position: relative;
}

@media (max-width: 575.98px) {
    .my-gallery {
        margin-top: -50px !important;
    }
}

.dropdown-menu {
    background-color: #202020 !important;
}
.no-box-shadow {
    box-shadow: none !important;
    background-color: unset !important;
    background: transparent !important;
}


.logo-grid-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.logo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
}

.logo {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.logo-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

.text-center {
    text-align: center;
}

@font-face {
    font-family: 'mainFont';
    src: url('../fonts/ChangaOne-Italic.ttf') format('ttf'),
        url('../fonts/ChangaOne-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'clubx';
    src: url(../fonts/ShadowsIntoLight-Regular.ttf);
    font-weight: normal;
    font-style: normal;
}

.org-title {
    font-size: 120px;
    width: 100%;
}

@media (max-width: 575.98px) {
    .org-title {
        font-size: 68px;
    }
}

.org-biLine {
    font-weight: 100;
}

.gradient-div {
    position: absolute;
    top: 0px;
    left: 0px;
    background: linear-gradient(to bottom, transparent, black);
}

@media (max-width: 575.98px) {
    .basic-header-logo {
        max-height: 100px !important;
        margin-top: 0px;
    }

    .org-biLine {
        font-size: 12px;
    }

    .basic-header-text {
        font-size: 12px !important;
    }
}

.row {
    margin-right: 0;
    margin-left: 0;
}

@media (min-width: 1025px) {
    .h-custom {
        height: 100vh !important;
    }
}

.ticket-modal .card {
    background-color: #ffffff !important;
    margin-bottom: 10px;
    color: black !important;
}

/* .ticket-modal.card-text,
.card-title,
.card-header,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: black !important;
} */

hr {
    width: 100% !important;
    margin: 5px 0 !important;
}

.ticket-modal .card-header {
    align-items: center !important;
    align-content: center !important;
    flex: 1 1 auto !important;
}

.ticket-modal .card-header .card-title {
    margin-top: 10px !important;
    font-weight: 500;
    font-size: 18px;
}

.ticket-modal .quantity {
    margin: 7px;
    font-size: 20px;
    font-weight: bold;
    display: inline-block;
    position:relative;
    top: 5px;
}

.ticket-modal .pull-right{
    align-items: center !important;
    align-content: center !important;
}

.ticket-price {
    font-size: 20px;
    font-weight: 300;
    display:block;
    position:relative;
}
.ticket-fee {
    font-size: 12px;
    display:block;
    position:relative;
    color: #282828;
}
.ticket-end-date {
    font-size: 12px;
    display:block;
    position:relative;
    top: 5px;
}

/* change all inputs inside of .checkout-page to color black  */
.checkout-page input {
    color: black !important;
}

.pointer {
    cursor: pointer;
}

.spacer {
    margin-top: 20px;
}

.ticket-card {
    height: auto;
    width: 250px;
    padding: 0;
}

/* in mobile view. no wihth */
@media (max-width: 575.98px) {

    .ticket-card {
        width: unset !important;
        margin: 20px 60px;
    }
}

.event-details p {
    margin-bottom: 20px;
}

.event-details ul li {
    margin-bottom: 5px;
}

.text-black {
    color: black !important;
}


/*Profile Card 1*/
.profile-card-1 {
    font-family: 'Open Sans', Arial, sans-serif;
    position: relative;
    float: left;
    overflow: hidden;
    width: 100%;
    color: #ffffff;
    text-align: center;
    border:none;
  }
  .profile-card-1 .background {
    width:100%;
    vertical-align: top;
    opacity: 0.9;
    -webkit-filter: blur(8px);
    filter: blur(8px);
  }
  .profile-card-1 .card-content {
    width: 100%;
    padding: 15px 25px;
    position: absolute;
    left: 0;
    top: 50%;
  }
  .profile-card-1 .profile {
    position: absolute;
    bottom: 50%;
    left: 50%;
    max-width: 100px;
    opacity: 1;
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.5);
    border: 2px solid rgba(255, 255, 255, 1);
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
  }
  .profile-card-1 h2 {
    margin: 0 0 5px;
    font-weight: 600;
    font-size:25px;
  }
  .profile-card-1 h2 small {
    display: block;
    font-size: 15px;
    margin-top:10px;
  }
  .profile-card-1 i {
    display: inline-block;
      font-size: 16px;
      color: #ffffff;
      text-align: center;
      border: 1px solid #fff;
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 50%;
      margin:0 5px;
  }
  .profile-card-1 .icon-block{
      float:left;
      width:100%;
      margin-top:15px;
  }
  .profile-card-1 .icon-block a{
      text-decoration:none;
  }
  .profile-card-1 i:hover {
    background-color:#fff;
    color:#2E3434;
    text-decoration:none;
  }

  .faq-p {
    font-size: 18px;
    font-weight: 200;
  }

  .dress-code-title {
        font-size: 4vw;
        font-weight: 700;
  }

  @media (max-width: 575.98px) {
    .dress-code-title {
        font-size: 8vw;
    }
  }